body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
		"Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

:root {
	--prm: 40px;
	--prm-lg: 60px;
	--mobile-bp: 1440px;

	@media screen and (max-width: 1440px) {
		--prm: 15px;
		--prm-lg: 30px;
	}
}
